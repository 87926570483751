<template>
  <div class="margin-config fill-height text-center" v-if="!isLoading">
    <v-row justify="center" align="center">
      <v-col cols="12">
        <span class="my-folders-text">Configuraciones / </span>
        <span>Países</span>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <v-col cols="8">
        <v-text-field
          v-model="countryListSearch"
          label="Buscar..."
        >
        </v-text-field>
      </v-col>
      <v-col style="align-self: center; text-align: right;" cols="4">
        <v-btn
          depressed
          color="default-btn primary"
          @click="createCountryDialog = true, isCreateOrUpdate = true"
        >
          Agregar país
        </v-btn>
      </v-col>
      <v-col cols="12">
        <span class="my-folders-text">
          <v-icon color="secondary">
            mdi-exclamation-thick
          </v-icon>
            El campo código relacionado con cada país no podrá ser editado después de su creación.
            Para la edición del nombre de cada país se recomienda que este relacionado con el código previamente ingresado.</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <h5>Nombre</h5>
      </v-col>
      <v-col cols="2">
        <h5>Código</h5>
      </v-col>
      <v-col cols="3">
        <h5>Usuario mod</h5>
      </v-col>
      <v-col cols="3">
        <h5>Última actualización</h5>
      </v-col>
      <v-col cols="1">
        <h5>Acción</h5>
      </v-col>
    </v-row>
    <v-data-iterator
      :items="countryList"
      :search="countryListSearch"
      loading-text="Cargando"
      :loading="countryListLoader"
      no-data-text="No hay países creados"
      :items-per-page="8"
      :footer-props="{itemsPerPageOptions:[8, 16, -1]}"
    >
      <template v-slot:default="props">
          <div v-for="(countryItem, index) in props.items" :key="index">
          <v-row :class="index%2 === 0 ? 'backWhite' : 'backGray'">
            <v-col cols="3">
              <span> {{ countryItem.name }} </span>
            </v-col>
            <v-col cols="2">
              <span> {{ countryItem.countryCode }} </span>
            </v-col>
            <v-col cols="3">
              <span> {{ countryItem.usermod }} </span>
            </v-col>
            <v-col cols="3">
              <span> {{ countryItem.updated }} </span>
            </v-col>
            <v-col cols="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="editCountry(countryItem)"
                  >
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                    >mdi-pencil-outline</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </v-col>
          </v-row>
          </div>
      </template>
  </v-data-iterator>
    <v-dialog
      max-width='500px'
      v-model="createCountryDialog"
      @click:outside="closeDialog()"
      @keydown.esc="closeDialog()"
    >
      <v-card class="pa-4" max-width="auto" height="auto">
        <v-card-title class="center-dialog centered-dialog__card">
          <v-icon class="mx-1">mdi-flag-outline</v-icon>
          <h4>{{ getCreateOrEdit() }} país</h4>
          <v-icon class="mx-1">mdi-flag-outline</v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="countryForm" v-model="countryForm.isValid">
            <v-row no-gutters>
              <v-col class="px-8 py-0" cols="12">
                <label class="form-label" for="name">
                  Nombre
                </label>
                <v-text-field
                  class="form-field mt-2"
                  :rules="textFieldRules"
                  name="name"
                  outlined
                  single-line
                  background-color="white"
                  v-model="countryForm.name"
                />
              </v-col>
              <v-col class="px-8 py-0" cols="12">
                <label class="form-label" for="countryCode">
                  Código
                </label>
                <v-text-field
                  class="form-field mt-2"
                  :rules="textFieldRules"
                  :disabled="countryCodeDisabled"
                  name="countryCode"
                  outlined
                  single-line
                  background-color="white"
                  v-model="countryForm.countryCode"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters justify="center">
            <v-col cols="auto" class="pt-6 mr-3">
              <v-btn
                depressed
                color="default-btn accent"
                @click="closeDialog()"
              >
                Cerrar
              </v-btn>
            </v-col>
            <v-col cols="auto" class="pt-6 ml-3">
              <v-btn
                depressed
                color="default-btn primary"
                :disabled="!countryForm.isValid"
                :loading="createOrEditBtnLoader"
                @click="createOrUpdateountry()"
              >
                {{ getCreateOrEdit() }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <div v-else class="loading-container">
    <Loader />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { colors } from '@/utils/colors'

/* Components */
import Loader from '@/components/Base/Loader'

export default {
  name: 'Countries',
  components: {
    Loader
  },
  mixins: [],

  data () {
    return {
      isLoading: false,
      countryList: [],
      countryListSearch: '',
      countryListLoader: false,
      createCountryDialog: false,
      countryCodeDisabled: false,
      countryForm: {
        name: '',
        countryCode: '',
        id: '',
        isValid: ''
      },
      textFieldRules: [
        v => !!v || 'Este campo es requerido'
      ],
      isCreateOrUpdate: false, // false for edit and true for create,
      createOrEditBtnLoader: false
    }
  },

  created () {
    this.countryListLoader = true
    this.getCountries()
  },

  computed: {
    // ...mapState('contract', ['contracts', 'topFolders']),
  },

  methods: {
    ...mapActions('administration', ['getAdminCountries', 'addAdminCountries', 'updateAdminCountries']),
    ...mapMutations({ setSnackbar: 'SET_SNACKBAR' }),

    getCountries () {
      this.getAdminCountries()
        .then(response => {
          this.countryListLoader = false
          this.countryList = response.data
          this.closeDialog()
        })
        .catch(() => {
          this.countryListLoader = false
        })
    },

    editCountry (item) {
      this.isCreateOrUpdate = false
      this.countryCodeDisabled = true
      this.countryForm.name = item.name
      this.countryForm.countryCode = item.countryCode
      this.countryForm.id = item.id
      this.createCountryDialog = true
    },

    createOrUpdateountry () {
      this.createOrEditBtnLoader = true
      if (this.isCreateOrUpdate) {
        const request = {
          name: this.countryForm.name,
          countryCode: this.countryForm.countryCode
        }
        this.addAdminCountries(request)
          .then(() => {
            this.createOrEditBtnLoader = false
            this.getCountries()
            this.setSnackbar({
              text: 'País registrado correctamente',
              timeout: 5000,
              showing: true,
              color: colors.success
            })
          })
          .catch(error => {
            this.createOrEditBtnLoader = false
            const data = error.response.data
            if (data.countryCode) {
              this.setSnackbar({
                text: data.countryCode.message,
                timeout: 5000,
                showing: true,
                color: colors.error
              })
            } else {
              this.setSnackbar({
                text: 'No se pudo crear el país',
                timeout: 5000,
                showing: true,
                color: colors.error
              })
            }
          })
      } else {
        const request = {
          name: this.countryForm.name,
          id: this.countryForm.id
        }
        this.updateAdminCountries(request)
          .then(() => {
            this.createOrEditBtnLoader = false
            this.getCountries()
            this.setSnackbar({
              text: 'País actualizado correctamente',
              timeout: 5000,
              showing: true,
              color: colors.success
            })
          })
      }
    },

    getCreateOrEdit () {
      return this.isCreateOrUpdate ? 'Crear' : 'Editar'
    },

    closeDialog () {
      this.createCountryDialog = false
      this.countryCodeDisabled = false
      this.$refs.countryForm.resetValidation()
      this.countryForm = {
        name: '',
        countryCode: '',
        isValid: false
      }
    }
  }
}
</script>

<style lang="scss">
.margin-config{
  padding-left: 10%;
  padding-right: 5%;
}

.my-folders-text{
  color: gray;
}

.center-dialog{
  text-align: center;
  justify-content: center;
}

</style>
